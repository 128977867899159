require("../require-theme.js");
require("./page.scss");

/**
 * Retour à l'accueil au click du bouton du lnc-web-illustrative-feedback
 */
document.querySelector("#bouton-retour-acceuil")?.addEventListener("click", _onRetourAcceuil);

/**
 * Retour à la page d'acceuil
 * @private
 */
function _onRetourAcceuil() {
  window.location.href = "/";
}
